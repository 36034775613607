import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { PanelHeader, Spinner } from '../../components';
import { auth } from 'firebase';
import { notify } from '../../helpers/actions/index';

class User extends React.Component {
  state = {
    loading: false,
    password: '',
    repeatPassword: ''
  };

  updatePassword() {
    let { password, repeatPassword } = this.state;
    let { dispatch } = this.props;
    var user = auth().currentUser;

    if (password?.length <= 6) {
      this.setState({ loading: false });
      dispatch(
        notify(
          'danger',
          'Votre nouveau mot de passe doit comporter plus de 6 caractères'
        )
      );
    } else if (repeatPassword !== password) {
      this.setState({ loading: false });
      dispatch(
        notify('danger', 'Les deux mots de passe doivent être identiques')
      );
    } else {
      this.setState({ loading: true });
      user
        .updatePassword(password)
        .then(function() {
          dispatch(notify('info', 'Votre mot de passe a été changé'));
        })
        .catch(error => {
          dispatch(notify('danger', error?.message));
        })
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    let { loading } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardHeader>
              <h5 className="title">
                <Trans>Change Password</Trans>
              </h5>
            </CardHeader>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={event => {
                  event.preventDefault();
                  this.updatePassword();
                }}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        <Trans>New password</Trans>
                      </Label>
                      <Input
                        required="required"
                        minLength={6}
                        type="password"
                        value={this.state.password}
                        onChange={event =>
                          this.setState({ password: event.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        <Trans>Répétez votre mot de passe</Trans>
                      </Label>
                      <Input
                        required="required"
                        minLength={6}
                        type="password"
                        value={this.state.repeatPassword}
                        onChange={event =>
                          this.setState({ repeatPassword: event.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Button
                        type="submit"
                        color="info"
                        className="float-right"
                      >
                        <Trans>Save</Trans>
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(User));

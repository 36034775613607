import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  PanelHeader,
  Spinner,
  UploadImage,
  GoogleMapLibrary
} from '../../components';
import get from 'lodash/get';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { photo, save } from '../../helpers/actions/users';
import avatar from '../../assets/img/default-avatar.png';
import { UserData } from '../../helpers/api';

class User extends React.Component {
  state = {
    loading: true,
    profile: {}
  };

  componentWillMount() {
    this.setState({ loading: true });

    UserData()
      .then(({ user }) => {
        this.setState({ profile: user, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  changeAvatar(e) {
    e.preventDefault();
    this.setState({ loading: true });

    let { profile } = this.state,
      { dispatch } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      dispatch(photo(profile._id, file))
        .then(({ photoUrl }) => {
          profile.photoURL = reader.result;
          this.setState({ profile, loading: false });
        })
        .catch(() => this.setState({ loading: false }));
    };
    reader.readAsDataURL(file);
  }

  onChange(name, nameValid, value, valid) {
    let { profile } = this.state;

    if (name === 'work' || name === 'mobile' || name === 'landline') {
      this.setState({
        profile: { ...profile, phone: { ...profile.phone, [name]: value } },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      });
    } else {
      this.setState({
        profile: { ...profile, [name]: value },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      });
    }
  }

  validateProfile() {
    let name = this.name;
    let address = this.address;
    let work = this.work;
    let mobile = this.mobile;
    let landline = this.landline;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      [address.attributes.getNamedItem('namevalid').value]: address.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      name.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid
    );
  }

  async updateProfile() {
    if (this.validateProfile()) {
      this.setState({ loading: true });

      let { profile } = this.state,
        { dispatch } = this.props;

      if (profile.address && profile.address !== '') {
        await geocodeByAddress(profile.address)
          .then(results => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            profile.lat = lat;
            profile.lng = lng;
          })
          .catch(() => {
            profile.lat = null;
            profile.lng = null;
          });
      } else {
        profile.lat = null;
        profile.lng = null;
      }

      dispatch(save(profile))
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  render() {
    let { loading, profile } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardHeader>
              <h5 className="title">
                <Trans>Edit Profile</Trans>
              </h5>
            </CardHeader>
            <CardBody>
              {profile ? (
                <Form className="form-horizontal">
                  <Row>
                    <Col xs={12} md={3}>
                      <FormGroup className={'text-center pt-2'}>
                        <UploadImage
                          avatar={true}
                          image={profile.photoURL ? profile.photoURL : avatar}
                          textButton={'Change Avatar'}
                          inputName={'avatarImage'}
                          onChange={event => this.changeAvatar(event)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={9}>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup>
                            <Label>
                              <Trans>Email</Trans>
                            </Label>
                            <Input
                              type="email"
                              disabled={true}
                              value={get(profile, 'email', '')}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.nameValid}
                          >
                            <Label>
                              <Trans>Display Name</Trans>
                            </Label>
                            <Input
                              type="text"
                              innerRef={node => (this.name = node)}
                              namevalid="nameValid"
                              value={get(profile, 'displayName', '')}
                              required="required"
                              onChange={event =>
                                this.onChange(
                                  'displayName',
                                  'nameValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup>
                            <Label>
                              <Trans>Company</Trans>
                            </Label>
                            <Input
                              type="text"
                              disabled={true}
                              value={'Placeshaker'}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup
                            className={'has-label ' + this.state.addressValid}
                          >
                            <Label>
                              <Trans>Address</Trans>
                            </Label>
                            <GoogleMapLibrary>
                              <PlacesAutocomplete
                                value={profile.address ? profile.address : ''}
                                onChange={address =>
                                  this.onChange(
                                    'address',
                                    'addressValid',
                                    address,
                                    true
                                  )
                                }
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps
                                }) => (
                                  <div>
                                    <input
                                      ref={node => (this.address = node)}
                                      {...getInputProps({
                                        className: 'form-control',
                                        namevalid: 'addressValid'
                                      })}
                                    />
                                    <ul
                                      className={
                                        'dropdown-menu inner ' +
                                        (suggestions.length === 0 ? '' : 'show')
                                      }
                                    >
                                      {suggestions.map(suggestion => (
                                        <li
                                          {...getSuggestionItemProps(
                                            suggestion
                                          )}
                                        >
                                          <a
                                            href="/#"
                                            className="dropdown-item"
                                          >
                                            <span className="text">
                                              {suggestion.description}
                                            </span>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </GoogleMapLibrary>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Label>
                            <Trans>Phones</Trans>
                          </Label>
                          <hr className={'mt-0'} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.workValid}
                          >
                            <Label>
                              <Trans>Work</Trans>
                            </Label>
                            <Input
                              type="text"
                              innerRef={node => (this.work = node)}
                              namevalid="workValid"
                              value={get(profile.phone, 'work', '')}
                              onChange={event =>
                                this.onChange(
                                  'work',
                                  'workValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.mobileValid}
                          >
                            <Label>
                              <Trans>Mobile</Trans>
                            </Label>
                            <Input
                              type="text"
                              innerRef={node => (this.mobile = node)}
                              namevalid="mobileValid"
                              value={get(profile.phone, 'mobile', '')}
                              onChange={event =>
                                this.onChange(
                                  'mobile',
                                  'mobileValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                          <FormGroup
                            className={'has-label ' + this.state.landlineValid}
                          >
                            <Label>
                              <Trans>Landline</Trans>
                            </Label>
                            <Input
                              type="text"
                              innerRef={node => (this.landline = node)}
                              namevalid="landlineValid"
                              value={get(profile.phone, 'landline', '')}
                              onChange={event =>
                                this.onChange(
                                  'landline',
                                  'landlineValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              ) : null}
            </CardBody>
            <CardFooter className={'text-right'}>
              <Button color="info" onClick={() => this.updateProfile()}>
                <Trans>Save</Trans>
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(User));
